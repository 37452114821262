

export const getter_Chinese = {

    methods: {

    get_Ch_Sign(b_date) {

    let        bx = String(b_date)
    let yyyy = bx.slice(0,4) 
  
    let                m5 = bx.substring(5)
    let mx = parseInt( m5.slice(0,2) )
  
    let dx = parseInt( bx.slice(-2) ) 
  
                                let ch_sign = ''
  
    switch(yyyy)    { case '1951' : ch_sign = 'Rabbit'  ; break ;
                      case '1952' : ch_sign = 'Dragon'  ;  break ; 
                      case '1953' : ch_sign = 'Snake'   ;  break ; 
                      case '1954' : ch_sign = 'Horse'   ;  break ; 
                      case '1955' : ch_sign = 'Goat'   ;  break ; 
                      case '1956' : ch_sign = 'Monkey'  ;  break ; 
                      case '1957' : ch_sign = 'Rooster' ;  break ; 
                      case '1958' : ch_sign = 'Dog'     ;  break ; 
                      case '1959' : ch_sign = 'Pig'     ;  break ; 
                      case '1960' : ch_sign = 'Rat'     ;  break ; 
                      case '1961' : ch_sign = 'Ox'    ; break ; 
                      case '1962' : ch_sign = 'Tiger'   ; break ; 
  
                      case '1963' : ch_sign = 'Rabbit'  ; break ; 
                      case '1964' : ch_sign = 'Dragon'  ; break ; 
  
                      case '1965' : ch_sign = 'Snake'   ; if (  mx == 1                   ) { ch_sign = 'Dragon'   } 
                                                          if ( (mx == 2) && ( dx <  2   ) ) { ch_sign = 'Dragon'   } ; break ;   
  
                      case '1966' : ch_sign = 'Horse'   ; if ( (mx == 1) && ( dx <  21   ) ) { ch_sign = 'Snake'   } ; break ;   
  
                      case '1967' : ch_sign = 'Goat'    ; if (  mx == 1                   ) { ch_sign = 'Horse'   } 
                                                          if ( (mx == 2) && ( dx <  9   ) ) { ch_sign = 'Horse'   } ; break ;   
  
                      case '1968' : ch_sign = 'Monkey'  ; if ( (mx == 1) && ( dx <  31  ) ) { ch_sign = 'Goat'  } ; break ;  
  
                      case '1969' : ch_sign = 'Rooster' ; if (  mx == 1                   ) { ch_sign = 'Monkey'  } 
                                                          if ( (mx == 2) && ( dx <  17  ) ) { ch_sign = 'Monkey'  } ; break ;  
  
                      case '1970' : ch_sign = 'Dog'     ; if (  mx == 1                  ) { ch_sign = 'Rooster'  } 
                                                          if ( (mx == 2) && ( dx <  6  ) ) { ch_sign = 'Rooster'  } ; break ;  
  
    case '1971' : ch_sign = 'Pig'     ; if ( (mx == 1) && ( dx <  27 ) ) { ch_sign = 'Dog'  } ; break ;  
  
    case '1972' : ch_sign = 'Rat'     ; if (  mx == 1                  ) { ch_sign = 'Pig'  } ; break ;
                                        if ( (mx == 2) && ( dx <  15 ) ) { ch_sign = 'Pig'  } ; break ;  
  
    case '1973' : ch_sign = 'Ox'    ; if (  mx == 1                  ) { ch_sign = 'Rat'  } ; break ;
                                        if ( (mx == 2) && ( dx <  3  ) ) { ch_sign = 'Rat'  } ; break ; 
  
    case '1974' : ch_sign = 'Tiger'   ; if ( (mx == 1) && ( dx <  24 ) ) { ch_sign = 'Ox'  } ; break ;
  
    case '1975' : ch_sign = 'Rabbit'  ; if (  mx == 1                  ) { ch_sign = 'Tiger'  } ; break ;
                                        if ( (mx == 2) && ( dx <  11 ) ) { ch_sign = 'Tiger'  } ; break ; 
  
    case '1976' : ch_sign = 'Dragon'  ; if ( (mx == 1) && ( dx <  31 ) ) { ch_sign = 'Rabbit'  } ; break ; 
  
    case '1977' : ch_sign = 'Snake'   ; if (  mx == 1                 ) { ch_sign = 'Dragon'  } ; break ;
                                        if ( (mx == 2) && ( dx < 11 ) ) { ch_sign = 'Dragon'  } ; break ; 
  
    case '1978' : ch_sign = 'Horse'   ; if (  mx == 1                  ) { ch_sign = 'Snake'  } ; break ;
                                        if ( (mx == 2) && ( dx <  7 )  ) { ch_sign = 'Snake'  } ; break ; 
  
    case '1979' : ch_sign = 'Goat'   ; if ( (mx == 1) && ( dx < 28 ) ) { ch_sign = 'Horse' } ; break ; 
  
    //_______________________________________________________________________________________________________
    case '1980' : ch_sign = 'Monkey'  ; if (  mx == 1                  ) { ch_sign = 'Goat'  } ; break ;
                                        if ( (mx == 2) && ( dx < 16 )  ) { ch_sign = 'Goat'  } ; break ; 
  
    case '1981' : ch_sign = 'Rooster' ; if (  mx == 1                  ) { ch_sign = 'Monkey'  } ; break ;
                                        if ( (mx == 2) && ( dx <  5 )  ) { ch_sign = 'Monkey'  } ; break ; 
  
    case '1982' : ch_sign = 'Dog'     ; if ( (mx == 1) && ( dx < 25 ) ) { ch_sign = 'Rooster'  } ; break ; 
  
    case '1983' : ch_sign = 'Pig'     ; if (  mx == 1                 ) { ch_sign = 'Dog'  } ; break ;
                                        if ( (mx == 2) && ( dx < 13 ) ) { ch_sign = 'Dog'  } ; break ; 
  
    case '1984' : ch_sign = 'Rat'     ; if (  mx == 1                 ) { ch_sign = 'Pig'  } ; break ;
                                        if ( (mx == 2) && ( dx <  2 ) ) { ch_sign = 'Pig'  } ; break ; 
  
    case '1985' : ch_sign = 'Ox'    ; if (  mx == 1                 ) { ch_sign = 'Rat'  } ; break ;
                                        if ( (mx == 2) && ( dx < 20 ) ) { ch_sign = 'Rat'  } ; break ; 
  
    case '1986' : ch_sign = 'Tiger'   ; if (  mx == 1                 ) { ch_sign = 'Ox' } ; break ;
                                        if ( (mx == 2) && ( dx <  9 ) ) { ch_sign = 'Ox' } ; break ; 
  
    case '1987' : ch_sign = 'Rabbit'  ; if ( (mx == 1) && ( dx < 29 ) ) { ch_sign = 'Tiger' } ; break ; 
  
    case '1988' : ch_sign = 'Dragon'  ; if (  mx == 1                  ) { ch_sign = 'Rabbit' } ; break ;
                                        if ( (mx == 2) && ( dx <  17 ) ) { ch_sign = 'Rabbit' } ; break ; 
  
    case '1989' : ch_sign = 'Snake'   ; if (  mx == 1                 ) { ch_sign = 'Dragon' } ; break ;
                                        if ( (mx == 2) && ( dx <  6 ) ) { ch_sign = 'Dragon' } ; break ; 
    //_______________________________________________________________________________________________________
    case '1990' : ch_sign = 'Horse'   ; if ( (mx == 1) && ( dx < 27 ) ) { ch_sign = 'Snake' } ; break ; 
  
    case '1991' : ch_sign = 'Goat'   ; if (  mx == 1                 ) { ch_sign = 'Horse' } ; break ;
                                        if ( (mx == 2) && ( dx <  6 ) ) { ch_sign = 'Horse' } ; break ; 
  
    case '1992' : ch_sign = 'Monkey'  ; if (  mx == 1                  ) { ch_sign = 'Goat'  } ; break ;
                                        if ( (mx == 2) && ( dx <  4 )  ) { ch_sign = 'Goat'  } ; break ; 
  
    case '1993' : ch_sign = 'Rooster' ; if ( (mx == 1) && ( dx < 23 )  ) { ch_sign = 'Monkey'}  ; break ; ;
  
    case '1994' : ch_sign = 'Dog'     ; if (  mx == 1                  ) { ch_sign = 'Rooster'  } ; break ;
                                        if ( (mx == 2) && ( dx <  10 ) ) { ch_sign = 'Rooster'  } ; break ; 
  
    case '1995' : ch_sign = 'Pig'     ; if ( (mx == 1) && ( dx < 31 )  ) { ch_sign = 'Dog' }  ; break ; ;
  
    case '1996' : ch_sign = 'Rat'     ; if (  mx == 1                  ) { ch_sign = 'Pig'  } ; break ;
                                        if ( (mx == 2) && ( dx <  19 ) ) { ch_sign = 'Pig'  } ; break ; 
  
    case '1997' : ch_sign = 'Ox'    ; if (  mx == 1                 ) { ch_sign = 'Rat'  } ; break ;
                                        if ( (mx == 2) && ( dx <  7 ) ) { ch_sign = 'Rat'  } ; break ; 
  
    case '1998' : ch_sign = 'Tiger'   ; if ( (mx == 1) && ( dx < 28 ) ) { ch_sign = 'Ox' } ; break ; 
  
    case '1999' : ch_sign = 'Rabbit'  ; if (  mx == 1                 ) { ch_sign = 'Tiger' } ; break ; 
                                        if ( (mx == 2) && ( dx <  7 ) ) { ch_sign = 'Tiger'  } ; break ; 
  
    case '2000' : ch_sign = 'Dragon'  ; if (  mx == 1                 ) { ch_sign = 'Rabbit' } ; break ;
                                        if ( (mx == 2) && ( dx <  5 ) ) { ch_sign = 'Rabbit' } ; break ; 
  
    case '2001' : ch_sign = 'Snake'   ;  break ; 
    case '2002' : ch_sign = 'Horse'   ;  break ; 
    case '2003' : ch_sign = 'Goat'   ;  break ; 
    case '2004' : ch_sign = 'Monkey'  ;  break ; 
    case '2005' : ch_sign = 'Rooster' ;  break ; 
    case '2006' : ch_sign = 'Dog'     ;  break ; 
    case '2007' : ch_sign = 'Pig'     ;  break ; 
    case '2008' : ch_sign = 'Rat'     ;  break ; 
    case '2009' : ch_sign = 'Ox'    ; break ; 
    case '2010' : ch_sign = 'Tiger'   ; break ; 
  
    case '2011' : ch_sign = 'Rabbit'  ; break ; 
    case '2012' : ch_sign = 'Dragon'  ; break ; 
    case '2013' : ch_sign = 'Snake'   ; break ; 
    case '2014' : ch_sign = 'Horse'   ; break ; 
    case '2015' : ch_sign = 'Goat'   ; break ; 
    case '2016' : ch_sign = 'Monkey'  ; break ; 
    case '2017' : ch_sign = 'Rooster' ; break ; 
  
    case '2018' : ch_sign = 'Dog'     ; if (  mx == 1                 ) { ch_sign = 'Rooster'}  ; break ; 
                                        if ( (mx == 2) && ( dx <  5 ) ) { ch_sign = 'Rooster'}  ; break ; 
  
    case '2019' : ch_sign = 'Pig'     ; if (  mx == 1                 ) { ch_sign = 'Dog'}      ; break ; 
                                        if ( (mx == 2) && ( dx <  5 ) ) { ch_sign = 'Dog'}      ; break ; 
  
    case '2020' : ch_sign = 'Rat'     ; if ( (mx == '01') && ( dx < 26 ) ) { ch_sign = 'Pig' } ; break ; ;
  
    case '2021' : ch_sign = 'Ox'    ; if ( (mx == '01') && ( dx < 26 ) ) { ch_sign = 'Pig' } ; break ; ;
    case '2022' : ch_sign = 'Tiger'   ; if ( (mx == '01') && ( dx < 26 ) ) { ch_sign = 'Pig' } ; break ; ;
  }
  
                          return  ch_sign  ;
  }
} 

}
