
export const get_Sign_Long = {
                            methods: {
                                        get_Sign_Long_Name(x_Sign_3){
                                                                        switch(x_Sign_3) {
                                                                                        case 'Ari': x_Sign_Long = 'Aries♈'  ; break ;    
                                                                                        case 'Tau': x_Sign_Long = 'Taurus♉' ; break ;    
                                                                                        case 'Gem': x_Sign_Long = 'Gemini♊' ; break ;    
                                                                                        case 'Can': x_Sign_Long = 'Cancer♋' ; break ;    
                                                                                        case 'Leo': x_Sign_Long = 'Leo♌'    ; break ;    
                                                                                        case 'Vir': x_Sign_Long = 'Virgo♍'  ; break ;    
                                                                                        case 'Lib': x_Sign_Long = 'Libra♎'  ; break ;    
                                                                                        case 'Sco': x_Sign_Long = 'Scorpio♏ '    ; break ;    
                                                                                        case 'Sag': x_Sign_Long = 'Sagittarius♐' ; break ;    
                                                                                        case 'Cap': x_Sign_Long = 'Capricorn♑'   ; break ;    
                                                                                        case 'Aqu': x_Sign_Long = 'Aquarius♒'    ; break ;    
                                                                                        case 'Pis': x_Sign_Long = 'Pisces♓'      ; break ;    
                                                                                        default   : x_Sign_Long = ''               ; break ;    
                                                                                }
                                                                                                return x_Sign_Long ;
                                                                }
                                    }
}
