import Vue       from 'vue'
import VueRouter from 'vue-router'
// import Home      from '../views/Home.vue'
import Famous    from '../views/Famous.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Famous',
  //   component: Famous
  // },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/bDate',
    name: 'BDate',
    component: () => import('../views/BDate.vue')
  },
  {
    path: '/bGrid',
    name: 'BGrid',
    component: () => import('../views/Birth_Grid.vue')
  },
  {
    path: '/famous',
    name: 'Famous',
    component: () => import('../views/Famous.vue')
  },
  {
    path: '/key',
    name: 'key',
    component: () => import('../views/Key.vue')
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('../views/Support.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/what',
    name: 'What',
    component: () => import('../views/What.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/chinese',
    name: 'Chinese',
    component: () => import('../views/Chinese.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
