
export const calc_Stars = {

    methods: {
                get_nbr_stars( ch_sign_2_3 ) {

                                    let ch_sign_1_3 = this.whos[0].year_Of ;

                                                                               let x_s = 0 ;
  
              if (ch_sign_1_3 == 'Rab') {      switch(ch_sign_2_3) {  case 'Rab' : x_s = 4 ; break ; 
                                                                      case 'Dra' : x_s = 1 ; break ; 
                                                                      case 'Sna' : x_s = 2 ; break ; 
                                                                      case 'Hor' : x_s = 3 ; break ; 
                                                                      case 'Goa' : x_s = 5 ; break ; 
                                                                      case 'Mon' : x_s = 3 ; break ; 
                                                                      case 'Roo' : x_s = 2 ; break ; 
                                                                      case 'Dog' : x_s = 4 ; break ; 
                                                                      case 'Pig' : x_s = 5 ; break ; 
                                                                      case 'Rat' : x_s = 3 ; break ; 
                                                                      case 'Ox' : x_s = 3 ; break ; 
                                                                      case 'Tig' : x_s = 3 ; break ; 
                                                                      default    : x_s = 0 ;           }
  
              } else if (ch_sign_1_3 == 'Dra') { switch(ch_sign_2_3) {  case 'Rab' : x_s = 1 ; break ; 
                                                                        case 'Dra' : x_s = 2 ; break ; 
                                                                        case 'Sna' : x_s = 3 ; break ; 
                                                                        case 'Hor' : x_s = 3 ; break ; 
                                                                        case 'Goa' : x_s = 3 ; break ; 
                                                                        case 'Mon' : x_s = 5 ; break ; 
                                                                        case 'Roo' : x_s = 4 ; break ; 
                                                                        case 'Dog' : x_s = 2 ; break ; 
                                                                        case 'Pig' : x_s = 3 ; break ; 
                                                                        case 'Rat' : x_s = 5 ; break ; 
                                                                        case 'Ox' : x_s = 2 ; break ; 
                                                                        case 'Tig' : x_s = 3 ; break ; 
                                                                        default    : x_s = 0 ;           }  
  
              } else if (ch_sign_1_3 == 'Sna') { switch(ch_sign_2_3) {  case 'Rab' : x_s = 2 ; break ; 
                                                                        case 'Dra' : x_s = 3 ; break ; 
                                                                        case 'Sna' : x_s = 3 ; break ; 
                                                                        case 'Hor' : x_s = 3 ; break ; 
                                                                        case 'Goa' : x_s = 3 ; break ; 
                                                                        case 'Mon' : x_s = 4 ; break ; 
                                                                        case 'Roo' : x_s = 5 ; break ; 
                                                                        case 'Dog' : x_s = 2 ; break ; 
                                                                        case 'Pig' : x_s = 2 ; break ; 
                                                                        case 'Rat' : x_s = 2 ; break ; 
                                                                        case 'Ox'  : x_s = 5 ; break ; 
                                                                        case 'Tig' : x_s = 1 ; break ; 
                                                                        default    : x_s = 0 ;           }  
  
              } else if (ch_sign_1_3 == 'Hor') { switch(ch_sign_2_3) {  case 'Rab' : x_s = 3 ; break ; 
                                                                        case 'Dra' : x_s = 3 ; break ; 
                                                                        case 'Sna' : x_s = 3 ; break ; 
                                                                        case 'Hor' : x_s = 5 ; break ; 
                                                                        case 'Goa' : x_s = 4 ; break ; 
                                                                        case 'Mon' : x_s = 3 ; break ; 
                                                                        case 'Roo' : x_s = 3 ; break ; 
                                                                        case 'Dog' : x_s = 5 ; break ; 
                                                                        case 'Pig' : x_s = 3 ; break ; 
                                                                        case 'Rat' : x_s = 2 ; break ; 
                                                                        case 'Ox'  : x_s = 1 ; break ; 
                                                                        case 'Tig' : x_s = 5 ; break ; 
                                                                        default    : x_s = 0 ;           }  
  
              } else if (ch_sign_1_3 == 'Goa') { switch(ch_sign_2_3) {  case 'Rab' : x_s = 5 ; break ; 
                                                                        case 'Dra' : x_s = 3 ; break ; 
                                                                        case 'Sna' : x_s = 3 ; break ; 
                                                                        case 'Hor' : x_s = 4 ; break ; 
                                                                        case 'Goa' : x_s = 5 ; break ; 
                                                                        case 'Mon' : x_s = 3 ; break ; 
                                                                        case 'Roo' : x_s = 2 ; break ; 
                                                                        case 'Dog' : x_s = 3 ; break ; 
                                                                        case 'Pig' : x_s = 5 ; break ; 
                                                                        case 'Rat' : x_s = 1 ; break ; 
                                                                        case 'Ox'  : x_s = 2 ; break ; 
                                                                        case 'Tig' : x_s = 3 ; break ; 
                                                                        default    : x_s = 0 ;           }  
  
              } else if (ch_sign_1_3 == 'Mon') { switch(ch_sign_2_3) {  case 'Rab' : x_s = 3 ; break ; 
                                                                        case 'Dra' : x_s = 5 ; break ; 
                                                                        case 'Sna' : x_s = 4 ; break ; 
                                                                        case 'Hor' : x_s = 3 ; break ; 
                                                                        case 'Goa' : x_s = 3 ; break ; 
                                                                        case 'Mon' : x_s = 3 ; break ; 
                                                                        case 'Roo' : x_s = 3 ; break ; 
                                                                        case 'Dog' : x_s = 3 ; break ; 
                                                                        case 'Pig' : x_s = 1 ; break ; 
                                                                        case 'Rat' : x_s = 5 ; break ; 
                                                                        case 'Ox'  : x_s = 3 ; break ; 
                                                                        case 'Tig' : x_s = 2 ; break ; 
                                                                        default    : x_s = 0 ;           }  
  
              } else if (ch_sign_1_3 == 'Roo') { switch(ch_sign_2_3) {  case 'Rab' : x_s = 2 ; break ; 
                                                                        case 'Dra' : x_s = 4 ; break ; 
                                                                        case 'Sna' : x_s = 5 ; break ; 
                                                                        case 'Hor' : x_s = 3 ; break ; 
                                                                        case 'Goa' : x_s = 3 ; break ; 
                                                                        case 'Mon' : x_s = 3 ; break ; 
                                                                        case 'Roo' : x_s = 2 ; break ; 
                                                                        case 'Dog' : x_s = 1 ; break ; 
                                                                        case 'Pig' : x_s = 3 ; break ; 
                                                                        case 'Rat' : x_s = 2 ; break ; 
                                                                        case 'Ox'  : x_s = 5 ; break ; 
                                                                        case 'Tig' : x_s = 3 ; break ; 
                                                                        default    : x_s = 0 ;           }  
  
              } else if (ch_sign_1_3 == 'Dog') { switch(ch_sign_2_3) {  case 'Rab' : x_s = 4 ; break ; 
                                                                        case 'Dra' : x_s = 2 ; break ; 
                                                                        case 'Sna' : x_s = 2 ; break ; 
                                                                        case 'Hor' : x_s = 5 ; break ; 
                                                                        case 'Goa' : x_s = 3 ; break ; 
                                                                        case 'Mon' : x_s = 3 ; break ; 
                                                                        case 'Roo' : x_s = 1 ; break ; 
                                                                        case 'Dog' : x_s = 3 ; break ; 
                                                                        case 'Pig' : x_s = 3 ; break ; 
                                                                        case 'Rat' : x_s = 3 ; break ; 
                                                                        case 'Ox'  : x_s = 3 ; break ; 
                                                                        case 'Tig' : x_s = 5 ; break ; 
                                                                        default    : x_s = 0 ;           }  
  
              } else if (ch_sign_1_3 == 'Pig') { switch(ch_sign_2_3) {  case 'Rab' : x_s = 5 ; break ; 
                                                                        case 'Dra' : x_s = 3 ; break ; 
                                                                        case 'Sna' : x_s = 2 ; break ; 
                                                                        case 'Hor' : x_s = 3 ; break ; 
                                                                        case 'Goa' : x_s = 5 ; break ; 
                                                                        case 'Mon' : x_s = 1 ; break ; 
                                                                        case 'Roo' : x_s = 3 ; break ; 
                                                                        case 'Dog' : x_s = 3 ; break ; 
                                                                        case 'Pig' : x_s = 3 ; break ; 
                                                                        case 'Rat' : x_s = 3 ; break ; 
                                                                        case 'Ox'  : x_s = 3 ; break ; 
                                                                        case 'Tig' : x_s = 4 ; break ; 
                                                                        default    : x_s = 0 ;           }  
  
              } else if (ch_sign_1_3 == 'Rat') { switch(ch_sign_2_3) {  case 'Rab' : x_s = 3 ; break ; 
                                                                        case 'Dra' : x_s = 5 ; break ; 
                                                                        case 'Sna' : x_s = 2 ; break ; 
                                                                        case 'Hor' : x_s = 2 ; break ; 
                                                                        case 'Goa' : x_s = 1 ; break ; 
                                                                        case 'Mon' : x_s = 5 ; break ; 
                                                                        case 'Roo' : x_s = 2 ; break ; 
                                                                        case 'Dog' : x_s = 3 ; break ; 
                                                                        case 'Pig' : x_s = 3 ; break ; 
                                                                        case 'Rat' : x_s = 3 ; break ; 
                                                                        case 'Ox'  : x_s = 4 ; break ; 
                                                                        case 'Tig' : x_s = 3 ; break ; 
                                                                        default    : x_s = 0 ;           }  
  
              } else if (ch_sign_1_3 == 'Ox') {      switch(ch_sign_2_3) {  case 'Rab' : x_s = 3 ; break ; 
                                                                            case 'Dra' : x_s = 2 ; break ; 
                                                                            case 'Sna' : x_s = 5 ; break ; 
                                                                            case 'Hor' : x_s = 1 ; break ; 
                                                                            case 'Goa' : x_s = 2 ; break ; 
                                                                            case 'Mon' : x_s = 3 ; break ; 
                                                                            case 'Roo' : x_s = 5 ; break ; 
                                                                            case 'Dog' : x_s = 3 ; break ; 
                                                                            case 'Pig' : x_s = 3 ; break ; 
                                                                            case 'Rat' : x_s = 4 ; break ; 
                                                                            case 'Ox'  : x_s = 3 ; break ; 
                                                                            case 'Tig' : x_s = 2 ; break ; 
                                                                            default    : x_s = 0 ;           }  
  
              } else if (ch_sign_1_3 == 'Tig') { switch(ch_sign_2_3) {  case 'Rab' : x_s = 3 ; break ; 
                                                                        case 'Dra' : x_s = 3 ; break ; 
                                                                        case 'Sna' : x_s = 1 ; break ; 
                                                                        case 'Hor' : x_s = 5 ; break ; 
                                                                        case 'Goa' : x_s = 3 ; break ; 
                                                                        case 'Mon' : x_s = 2 ; break ; 
                                                                        case 'Roo' : x_s = 3 ; break ; 
                                                                        case 'Dog' : x_s = 5 ; break ; 
                                                                        case 'Pig' : x_s = 4 ; break ; 
                                                                        case 'Rat' : x_s = 3 ; break ; 
                                                                        case 'Ox' : x_s = 2 ; break ; 
                                                                        case 'Tig' : x_s = 3 ; break ; 
                                                                        default    : x_s = 0 ;           }  
              }
                                                                   let nbr_Stars = x_s ;
                                                                return nbr_Stars 
            }
          }
        }