<template>
<v-container>

<v-row>
    <v-card max-width = "1400" elevation="2" outlined shaped class = "mx-2">
    <v-col>
            <!------------------------------------------------------------------------------------------------------------------------>  
              <div v-if = "this.whos.length == 0">
              <v-btn x-small
                     class = "text-right"    
                     color = "green"
                     dark
                     :to="{path: '/bDate'}" 
                     >
                             who me ?  
              </v-btn>
              </div>
            <!------------------------------------------------------------------------------------------------------------------------>  
            <v-data-table
                            class = "hidden-sm-and-down" 
                            dense
                            :headers="headers"
                            :items="famous"
                            :footer-props="{
                                              'items-per-page-options': [500,1000]
                              }"
                            :items-per-page = "500"
            > </v-data-table>
            <!------------------------------------------------------------------------------------------------------------------------>
            <v-simple-table 
              class = "hidden-md-and-up"
              dense
              >
              <template v-slot:default>

                <thead>
                      <tr>
                                                            <th style = "text-align:left">   <i> who                           </i> </th>
                                                            <th>                             <i> birthDay __ z _ el_yr _ ❤️☯️ </i> </th>                                                            
                      </tr> 
              </thead>

              <tbody>
                        <tr v-for = "person, kk in famous">
                                                            <td style = "text-align:left">   {{ person.first_last }}   </td>
                                                            <td style = "text-align:left">   {{ person.combo      }}   </td>
                        </tr>
                </tbody>

                </template>
            </v-simple-table>
            <!--------------------------------------------------------------->

  </v-col>  
  </v-card>

</v-row>  

</v-container>
</template>

<!------------------------------------------------------------>
<script>

  // import { calc_Court }  from '../mixins/calc_Court' ;
  import { calc_Hearts }      from '../mixins/calc_Hearts'     ;
  import { calc_Stars }      from '../mixins/calc_Stars'       ;
  import { getters }        from '../mixins/getters'        ;
  import { getter_Chinese } from '../mixins/getter_Chinese' ;
  import { get_Sign_Long }  from '../mixins/get_Sign_Long' ;
  import   jj_bdays_raw     from './bdays.json'     ;

  export default {

                    data: () => ({
                                    stars:    [],
                                    famous:   [],

                                    headers: [
                                                { text: 'first',        value: 'first' , align: 'start',  sortable: true,  },
                                                { text: 'last',      value: 'last' },
                                                { text: 'date',       value: 'MD' },
                                                { text: 'birth',      value: 'Y' },
                                                { text: 'sign',       value: 'zLong',  width: 140},
                                                { text: 'yr',         value: 'c_long', width: 160 },

                                                { text: '❤️',        value: 'hearts' },
                                                { text: '☯️',        value: 'c_Match_Icon_Long' },
                                            ],

                                  }),      
  //_________________________________________________________________________________________________________________________________
  mixins: [getters, calc_Hearts, calc_Stars, getter_Chinese, get_Sign_Long],

  methods: {
              //____________________________________________________________________________________
              z_Sign_column_handler() {
                                                    let x_sign_3 = '' ;
                                                    let x_sign_2 = '' ;
                                                    let cc = 0 ;

                                                    let x_nbr_hearts = 1 ;

                                                    let x_famous_sign     = '' ;
                                                    let x_Sign_Long = '' ;

                                                    let x_icon = '' ;
                                                    let z_Long = '' ;

                                                    let                  total_nbr_famous = this.famous.length ;
                                                    for (  cc = 0 ; cc < total_nbr_famous ; cc++) {
                                                                                                                 x_sign_3 = this.famous[cc].S ;
                                                                                                      x_sign_2 = x_sign_3.substring(0,2) ;
                                                                             this.famous[cc].sign_2 = x_sign_2 ;

                                                                                                         x_Sign_Long = this.get_Sign_Long(x_sign_3) ;
                                                                             this.famous[cc].sign_Long = x_Sign_Long ;  
                                                                             
                                                                             switch(x_sign_3) {
                                                                                                case 'Ari'  : x_icon = '♈🔥' ; break ;
                                                                                                case 'Tau'  : x_icon = '♉🌎' ; break ;
                                                                                                case 'Gem'  : x_icon = '♊✈️' ; break ;
                                                                                                case 'Can'  : x_icon = '♋💧' ; break ;
                                                                                                case 'Leo'  : x_icon = '♌🔥' ; break ;
                                                                                                case 'Vir'  : x_icon = '♍🌎' ; break ;
                                                                                                case 'Lib'  : x_icon = '♎✈️' ; break ;
                                                                                                case 'Sco'  : x_icon = '♏💧' ; break ;
                                                                                                case 'Sag'  : x_icon = '♐🔥' ; break ;
                                                                                                case 'Cap'  : x_icon = '♑🌎' ; break ;
                                                                                                case 'Aqu'  : x_icon = '♒✈️' ; break ;
                                                                                                case 'Pis'  : x_icon = '♓💧' ; break ;
                                                                                                default     : x_icon = '' ;
                                                                             }

                                                                             switch(x_sign_3) {
                                                                                                case 'Ari'  : z_Long = '♈Aries🔥'     ; break ;
                                                                                                case 'Tau'  : z_Long = '♉Taurus🌎'    ; break ;
                                                                                                case 'Gem'  : z_Long = '♊Gemini✈️'    ; break ;
                                                                                                case 'Can'  : z_Long = '♋Cancer💧'     ; break ;
                                                                                                case 'Leo'  : z_Long = '♌Leo🔥'        ; break ;
                                                                                                case 'Vir'  : z_Long = '♍Virgo🌎'      ; break ;
                                                                                                case 'Lib'  : z_Long = '♎Libra✈️'      ; break ;
                                                                                                case 'Sco'  : z_Long = '♏Scorpio💧'     ; break ;
                                                                                                case 'Sag'  : z_Long = '♐Sagittarius🔥' ; break ;
                                                                                                case 'Cap'  : z_Long = '♑Capricorn🌎'   ; break ;
                                                                                                case 'Aqu'  : z_Long = '♒Aquarius✈️'    ; break ;
                                                                                                case 'Pis'  : z_Long = '♓Pisces💧'       ; break ;
                                                                                                default     : z_Long = '' ;
                                                                             }

                                                                             this.famous[cc].icon  = x_icon ;
                                                                             this.famous[cc].zLong = z_Long ;

                                                                             this.famous[cc].combo =                         
                                                                                                      this.famous[cc].mm_dd_yy      +  
                                                                                                      this.famous[cc].icon          +  
                                                                                                      this.famous[cc].animal_icon   ;

                                                    }
                      },
              // __________________________________________________________________________________________________
              hearts_column_handler() {
                                                    let cc = 0 ;

                                                    let x_nbr_hearts = 1 ;

                                                    let x_famous_sign     = '' ;

                                                    let                  total_nbr_famous = this.famous.length ;
                                                    // //___________________________________________________________________________________
                                                     if ( this.whos === undefined || this.whos.length == 0 ) {        
                                                                                                                for ( cc = 0 ; cc < total_nbr_famous ; cc++) {
                                                                                                                                                               this.famous[cc].hearts = 1 ;
                                                                                                                                                             }
                                                                                                              }                      
                                                    else {
                                                            for ( cc = 0 ; cc < total_nbr_famous ; cc++ ) {
                                                                                                                               x_famous_sign = this.famous[cc].S ; 
                                                                                           x_nbr_hearts = this.get_nbr_hearts( x_famous_sign ) ;
                                                                  this.famous[cc].hearts = x_nbr_hearts ;
                                                            }                
                                                     }              
                      },
           //_____________________________________________________________________________________________________________________________________           
              chinese_column_handler() {
                                                    let c_sign   = '' ;
                                                    let c_sign_3 = '' ;
                                                    let cc = 0 ;

                                                    let x_nbr_stars = 1 ;

                                                    let x_famous_animal   = '' ;
                                                    let x_famous_animal_3 = '' ;

                                                    let c_icon          = '' ;
                                                    let ch_long          = '' ;

                                                    let c_Match_Icon = '' ;

                                                    let                  total_nbr_famous = this.famous.length ;
                                                    for (  cc = 0 ; cc < total_nbr_famous ; cc++) {
                                                                                                                 c_sign =  this.famous[cc].AN ;
                                                                                                      c_sign_3 = c_sign.substring(0,3)
                                                                             this.famous[cc].sign_3 = c_sign_3 ;

                                                                             switch(c_sign_3) {
                                                                                                case 'Rat'  : c_icon = '🐀' ; break ;
                                                                                                case 'Ox'   : c_icon = '🐮' ; break ;
                                                                                                case 'Tig'  : c_icon = '🐯' ; break ;
                                                                                                case 'Rab'  : c_icon = '🐰' ; break ;
                                                                                                case 'Dra'  : c_icon = '🐲' ; break ;
                                                                                                case 'Sna'  : c_icon = '🐍' ; break ;
                                                                                                case 'Hor'  : c_icon = '🐴' ; break ;
                                                                                                case 'Goa'  : c_icon = '🐐' ; break ;
                                                                                                case 'Mon'  : c_icon = '🐵' ; break ;
                                                                                                case 'Roo'  : c_icon = '🐔' ; break ;
                                                                                                case 'Dog'  : c_icon = '🐶' ; break ;
                                                                                                case 'Pig'  : c_icon = '🐷' ; break ;
                                                                                                default     : c_icon = ' '
                                                                             }

                                                                             switch(c_sign_3) {
                                                                                                case 'Rat'  : ch_long = '🐀 Rat ♂️'     ; break ;
                                                                                                case 'Ox'   : ch_long = '🐮 Ox ♀️'      ; break ;
                                                                                                case 'Tig'  : ch_long = '🐯 Tiger ♂️'   ; break ;
                                                                                                case 'Rab'  : ch_long = '🐰 Rabbit ♀️'  ; break ;
                                                                                                case 'Dra'  : ch_long = '🐲 Dragon ♂️'  ; break ;
                                                                                                case 'Sna'  : ch_long = '🐍 Snake ♀️'   ; break ;
                                                                                                case 'Hor'  : ch_long = '🐴 Horse ♂️'   ; break ;
                                                                                                case 'Goa'  : ch_long = '🐐 Goat ♀️'    ; break ;
                                                                                                case 'Mon'  : ch_long = '🐵 Monkey ♂️'  ; break ;
                                                                                                case 'Roo'  : ch_long = '🐔 Rooster ♀️' ; break ;
                                                                                                case 'Dog'  : ch_long = '🐶 Dog ♂️'     ; break ;
                                                                                                case 'Pig'  : ch_long = '🐷 Pig ♀️'     ; break ;
                                                                                                default     : ch_long = ' '
                                                                             }

                                                                             this.famous[cc].animal_icon = c_icon   ;
                                                                             this.famous[cc].c_sign      = c_sign ;

                                                                             this.famous[cc].c_long = ch_long ;

                                                                             this.famous[cc].combo =                         
                                                                                                      this.famous[cc].mm_dd_yy      +  
                                                                                                      this.famous[cc].icon          +  
                                                                                                      this.famous[cc].animal_icon   ;
                                                    }
                      },

//_____________________________________________________________________________________________________________________________________           
              chinese_simpatico_handler() {
                                                    // let c_sign   = '' ;
                                                    // let c_sign_3 = '' ;
                                                    let cc = 0 ;

                                                    let x_nbr_stars = 1 ;

                                                    let x_famous_animal   = '' ;
                                                    let x_famous_animal_3 = '' ;

                                                    let c_Match_Icon = '' ;
                                                    let c_Match_Icon_Long = '' ;

                                                    let total_nbr_famous = this.famous.length ;
                                                    //___________________________________________________________________________________
                                                    if ( this.whos === undefined || this.whos.length == 0 ) {        
                                                                                                              for ( cc = 0 ; cc < total_nbr_famous ; cc++) {
                                                                                                                                                              this.famous[cc].stars  = 1  ;
                                                                                                                                                           }
                                                                                                             }                      
                                                    else {
                                                            for ( cc = 0 ; cc < total_nbr_famous ; cc++ ) {
                                                                                                                            x_famous_animal = this.famous[cc].AN ; 
                                                                                                                            x_famous_animal_3 = x_famous_animal.substring(0,3) ;
                                                                                          x_nbr_stars = this.get_nbr_stars( x_famous_animal_3 ) ;
                                                                  this.famous[cc].stars = x_nbr_stars ;

                                                                             switch(x_nbr_stars) {
                                                                                                    case 1  : c_Match_Icon = '💣' ; c_Match_Icon_Long = '💣1' ; break ;
                                                                                                    case 2  : c_Match_Icon = '🧨' ; c_Match_Icon_Long = '🧨2' ; break ;
                                                                                                    case 3  : c_Match_Icon = '🥠' ; c_Match_Icon_Long = '🥠3' ; break ;
                                                                                                    case 4  : c_Match_Icon = '✨' ; c_Match_Icon_Long = '✨4' ; break ;
                                                                                                    case 5  : c_Match_Icon = '🧧' ; c_Match_Icon_Long = '🧧5' ; break ;
                                                                                                    default : c_Match_Icon = ''   ; 
                                                                                                  }

                                                                 this.famous[cc].c_Match_Icon      = c_Match_Icon ;            
                                                                 this.famous[cc].c_Match_Icon_Long = c_Match_Icon_Long ;            

                                                                 this.famous[cc].signs =  this.famous[cc].mm_dd_yyyy    +
                                                                                          this.famous[cc].icon          +
                                                                                          this.famous[cc].animal_icon   ;
                                                                                          
                                                                 this.famous[cc].bDay_hearts_signs =  this.famous[cc].MD            +  
                                                                                                      this.famous[cc].hearts        +  
                                                                                                      this.famous[cc].c_Match_Icon_Long  ; 

                                                                 this.famous[cc].combo =              this.famous[cc].mm_dd_yy      +  
                                                                                                      this.famous[cc].icon          +  
                                                                                                      this.famous[cc].animal_icon   + ' _ ' +
                                                                                                      this.famous[cc].hearts        +  
                                                                                                      this.famous[cc].c_Match_Icon  ; 
                                                            }                
                                                     }              
                      },

//_____________________________________________________________________________________________________________________________________           
           first_Last_Handler() {
                                  let cc      = 0 ;     
                                  let x_first = '' ;
                                  let x_last  = '' ;
                                  let x_last_first = '' ;
                                  let x_last_first_F = '' ;

                                  let x_first_last = '' ;
                                  let x_first_last_F = '' ;

                                  let                 total_nbr_famous = this.famous.length ;        
                                  for ( cc = 0 ; cc < total_nbr_famous ; cc++ ) {
                                                                                                     x_last  = this.famous[cc].L ;
                                                                                                                    x_first = this.famous[cc].F ;
                                                                                      x_last_first = x_last + '.' + x_first ;
                                                                                      x_last_first_F = x_last_first.substring(0,14) ;
                                                         this.famous[cc].last_first = x_last_first_F ;

                                                                                      x_first_last   = x_first + '.' + x_last ;
                                                                                      x_first_last_F = x_first_last.substring(0,14) ;
                                                         this.famous[cc].first_last = x_first_last_F ;

                                                         this.famous[cc].last  = x_last  ;
                                                         this.famous[cc].first = x_first ;
                                 }
           },

           //________________________________________________________________________________
           birth_Date_Handler() {
                                  let cc         = 0 ;     
                                  let mm_dd_yyyy = '' ;
                                  let mm_dd_yy   = '' ;
                                  let mm_dd      = '' ;

                                  let yyyy = '' ;
                                  let yy   = '' ;

                                  let                 total_nbr_famous = this.famous.length ;        
                                  for ( cc = 0 ; cc < total_nbr_famous ; cc++ ) {
                                                                                                                    yyyy =  this.famous[cc].Y  ;            
                                                                                                            yy   =  yyyy.substring(2,4) ;                                                           
                                                                                                      mm_dd_yyyy =  this.famous[cc].MD + '.' + yyyy  ;    
                                                                                                      mm_dd_yy   =  this.famous[cc].MD + '.' + yy    ;    
                                                                                                      mm_dd      =  this.famous[cc].MD               ;    
                                                                         this.famous[cc].mm_dd_yyyy = mm_dd_yyyy ;          
                                                                         this.famous[cc].mm_dd_yy   = mm_dd_yy   ;          
                                                                         this.famous[cc].mm_dd      = mm_dd      ;          
                                  }  
           }, 

            //_______________________________________________________________________________________________________________________
            byDateIndex(aa, bb) {
                                                         let rx =  0 ; 
                                      if ( aa.IX > bb.IX ) { rx =  1 ;
                               } else if ( aa.IX < bb.IX ) { rx = -1 ;
                               } else                      { rx =  0 ; 
                               }   
                                                      return rx ;
            }
  },

//_________________________________________________________________________________________________________________________________
  created() {
              const                                             STORAGE_KEY = '1442495937';
              this.whos     = JSON.parse(sessionStorage.getItem(STORAGE_KEY) || '[]' ) ;

                        let famous          = [] ;
                        let famous_Raw      = [] ;
                        let famous_R2       = [] ;
                        let famous_Sorted = [] ;
                        let mm_dd     = '' ;
                        let mm_str        = '' ;
                        let dd_str        = '' ;
                        let x_MM_Int = 0 ;
                        let x_DD_Int = 0 ;
                        let cc = 0 ;
                        let total_nbr_famous = 0 ;
                        let ix = 0 ;
                        let xx = '' ;

                        this.famous_Raw = Object.assign( famous_Raw, jj_bdays_raw) ;

                                                                          let month_Now = 0 ;
                                                                          let date_Now  = 0 ;

                                                                          let             x_Date = new Date() ;
                                                                              month_Now = x_Date.getMonth() + 1 ;

                                                                              date_Now = x_Date.getDate() ;

                                                                       total_nbr_famous = this.famous_Raw.length ;
                                                    for (cc = 0 ; cc < total_nbr_famous ; cc++  ) {
                                                                                                                                            mm_dd = famous_Raw[cc].MD ;
                                                                                                                                   mm_str = mm_dd.substring(0,2)  ;
                                                                                                               x_MM_Int = parseInt(mm_str) ;

                                                                                                                                   dd_str = mm_dd.substring(3,5)  ;
                                                                                                               x_DD_Int = parseInt(dd_str) ;

                                                                                                               if (  x_MM_Int <  month_Now)                              { ix = 1000 * ( x_MM_Int + 12 ) ; } 
                                                                                                          else if (( x_MM_Int == month_Now) && ( x_DD_Int < date_Now))   { ix = 1000 * ( x_MM_Int + 12 ) ; }  
                                                                                                          else                                                           { ix = 1000 * ( x_MM_Int      ) ; } 
                                                                                                                                        ix += x_DD_Int ;  
                                                                                                                    famous_Raw[cc].IX = ix ;  
                                                                                                  }
                        //________________________________________________________________________  ______________________________________________________________________                                                                                                                           
                        this.famous = famous_Raw.sort(this.byDateIndex) ;

                        this.first_Last_Handler()   ;
                        this.birth_Date_Handler()   ;
                        
                        this.z_Sign_column_handler() ;
                        this.chinese_column_handler() ;

                        this.hearts_column_handler() ;
                        this.chinese_simpatico_handler() ;
            }
  }

</script>

<!--------------------------------------------------------------------------------->
<style>

    th {
          text-align: center; 
          font-style: italic;
      }

    td {
          text-align: center; 
       }

</style>