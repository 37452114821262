
export const calc_Hearts = {

    methods: {
                get_nbr_hearts(sign_2_3) {

                    let sign_1_3 = '' ; 
                        sign_1_3 = this.whos[0].sign ;
                                                                             let x_hh = 0  
                  if (sign_1_3 == 'Ari') {      switch(sign_2_3) {  case 'Ari' : x_hh = 4 ; break ; 
                                                                    case 'Tau' : x_hh = 2 ; break ; 
                                                                    case 'Gem' : x_hh = 4 ; break ; 
                                                                    case 'Can' : x_hh = 2 ; break ; 
                                                                    case 'Leo' : x_hh = 5 ; break ; 
                                                                    case 'Vir' : x_hh = 1 ; break ; 
                                                                    case 'Lib' : x_hh = 3 ; break ; 
                                                                    case 'Sco' : x_hh = 3 ; break ; 
                                                                    case 'Sag' : x_hh = 5 ; break ; 
                                                                    case 'Cap' : x_hh = 3 ; break ; 
                                                                    case 'Aqu' : x_hh = 4 ; break ; 
                                                                    case 'Pis' : x_hh = 1 ; break ; 
                                                                    default    : x_hh = 0 ;           }  
    
             } else if (sign_1_3 == 'Tau') { switch(sign_2_3) { case 'Ari' : x_hh = 2 ; break ; 
                                                                case 'Tau' : x_hh = 5 ; break ; 
                                                                case 'Gem' : x_hh = 1 ; break ; 
                                                                case 'Can' : x_hh = 4 ; break ; 
                                                                case 'Leo' : x_hh = 3 ; break ; 
                                                                case 'Vir' : x_hh = 5 ; break ; 
                                                                case 'Lib' : x_hh = 3 ; break ; 
                                                                case 'Sco' : x_hh = 3 ; break ; 
                                                                case 'Sag' : x_hh = 1 ; break ; 
                                                                case 'Cap' : x_hh = 5 ; break ; 
                                                                case 'Aqu' : x_hh = 2 ; break ; 
                                                                case 'Pis' : x_hh = 4 ; break ; 
                                                                default    : x_hh = 0 ;         } 
                                                  
             } else if (sign_1_3 == 'Gem') { switch(sign_2_3) {                                                   
                                                                case 'Ari' : x_hh = 4 ; break ; 
                                                                case 'Tau' : x_hh = 1 ; break ; 
                                                                case 'Gem' : x_hh = 5 ; break ; 
                                                                case 'Can' : x_hh = 1 ; break ; 
                                                                case 'Leo' : x_hh = 4 ; break ; 
                                                                case 'Vir' : x_hh = 2 ; break ; 
                                                                case 'Lib' : x_hh = 5 ; break ; 
                                                                case 'Sco' : x_hh = 1 ; break ; 
                                                                case 'Sag' : x_hh = 3 ; break ; 
                                                                case 'Cap' : x_hh = 2 ; break ; 
                                                                case 'Aqu' : x_hh = 5 ; break ; 
                                                                case 'Pis' : x_hh = 1 ; break ; 
                                                                default    : x_hh = 0 ;          }  
                                                  
              } else if (sign_1_3 == 'Can') { switch(sign_2_3) {case 'Ari' : x_hh = 2 ; break ; 
                                                                case 'Tau' : x_hh = 4 ; break ; 
                                                                case 'Gem' : x_hh = 1 ; break ; 
                                                                case 'Can' : x_hh = 5 ; break ; 
                                                                case 'Leo' : x_hh = 3 ; break ; 
                                                                case 'Vir' : x_hh = 4 ; break ; 
                                                                case 'Lib' : x_hh = 1 ; break ; 
                                                                case 'Sco' : x_hh = 5 ; break ; 
                                                                case 'Sag' : x_hh = 2 ; break ; 
                                                                case 'Cap' : x_hh = 4 ; break ; 
                                                                case 'Aqu' : x_hh = 2 ; break ; 
                                                                case 'Pis' : x_hh = 5 ; break ; 
                                                                default    : x_hh = 0 ;         }  
                                                  
              } else if (sign_1_3 == 'Leo') { switch(sign_2_3) {case 'Ari' : x_hh = 5 ; break ; 
                                                                case 'Tau' : x_hh = 3 ; break ; 
                                                                case 'Gem' : x_hh = 4 ; break ; 
                                                                case 'Can' : x_hh = 3 ; break ; 
                                                                case 'Leo' : x_hh = 5 ; break ; 
                                                                case 'Vir' : x_hh = 1 ; break ; 
                                                                case 'Lib' : x_hh = 4 ; break ; 
                                                                case 'Sco' : x_hh = 3 ; break ; 
                                                                case 'Sag' : x_hh = 5 ; break ; 
                                                                case 'Cap' : x_hh = 2 ; break ; 
                                                                case 'Aqu' : x_hh = 2 ; break ; 
                                                                case 'Pis' : x_hh = 3 ; break ; 
                                                                default    : x_hh = 0 ;         }  
                                                  
              } else if (sign_1_3 == 'Vir') { switch(sign_2_3) {case 'Ari' : x_hh = 1 ; break ; 
                                                                case 'Tau' : x_hh = 5 ; break ; 
                                                                case 'Gem' : x_hh = 2 ; break ; 
                                                                case 'Can' : x_hh = 4 ; break ; 
                                                                case 'Leo' : x_hh = 1 ; break ; 
                                                                case 'Vir' : x_hh = 5 ; break ; 
                                                                case 'Lib' : x_hh = 2 ; break ; 
                                                                case 'Sco' : x_hh = 4 ; break ; 
                                                                case 'Sag' : x_hh = 1 ; break ; 
                                                                case 'Cap' : x_hh = 5 ; break ; 
                                                                case 'Aqu' : x_hh = 2 ; break ; 
                                                                case 'Pis' : x_hh = 2 ; break ; 
                                                                default    : x_hh = 0 ;         }  
                                                                                                
              } else if (sign_1_3 == 'Lib') { switch(sign_2_3) {case 'Ari' : x_hh = 3 ; break ; 
                                                                case 'Tau' : x_hh = 3 ; break ; 
                                                                case 'Gem' : x_hh = 5 ; break ; 
                                                                case 'Can' : x_hh = 1 ; break ; 
                                                                case 'Leo' : x_hh = 4 ; break ; 
                                                                case 'Vir' : x_hh = 2 ; break ; 
                                                                case 'Lib' : x_hh = 5 ; break ; 
                                                                case 'Sco' : x_hh = 2 ; break ; 
                                                                case 'Sag' : x_hh = 4 ; break ; 
                                                                case 'Cap' : x_hh = 2 ; break ; 
                                                                case 'Aqu' : x_hh = 5 ; break ; 
                                                                case 'Pis' : x_hh = 3 ; break ; 
                                                                default    : x_hh = 0 ;         }  
                                                  
              } else if (sign_1_3 == 'Sco') {switch(sign_2_3) { case 'Ari' : x_hh = 3 ; break ; 
                                                                case 'Tau' : x_hh = 3 ; break ; 
                                                                case 'Gem' : x_hh = 1 ; break ; 
                                                                case 'Can' : x_hh = 5 ; break ; 
                                                                case 'Leo' : x_hh = 3 ; break ; 
                                                                case 'Vir' : x_hh = 4 ; break ; 
                                                                case 'Lib' : x_hh = 2 ; break ; 
                                                                case 'Sco' : x_hh = 5 ; break ; 
                                                                case 'Sag' : x_hh = 2 ; break ; 
                                                                case 'Cap' : x_hh = 4 ; break ; 
                                                                case 'Aqu' : x_hh = 2 ; break ; 
                                                                case 'Pis' : x_hh = 5 ; break ; 
                                                                default    : x_hh = 0 ;          }  
                                                  
              } else if (sign_1_3 == 'Sag') { switch(sign_2_3) {case 'Ari' : x_hh = 5 ; break ; 
                                                                case 'Tau' : x_hh = 1 ; break ; 
                                                                case 'Gem' : x_hh = 3 ; break ; 
                                                                case 'Can' : x_hh = 2 ; break ; 
                                                                case 'Leo' : x_hh = 5 ; break ; 
                                                                case 'Vir' : x_hh = 1 ; break ; 
                                                                case 'Lib' : x_hh = 4 ; break ; 
                                                                case 'Sco' : x_hh = 2 ; break ; 
                                                                case 'Sag' : x_hh = 5 ; break ; 
                                                                case 'Cap' : x_hh = 1 ; break ; 
                                                                case 'Aqu' : x_hh = 4 ; break ; 
                                                                case 'Pis' : x_hh = 2 ; break ; 
                                                                default    : x_hh = 0 ;          }  
                                                  
              } else if (sign_1_3 == 'Cap') { switch(sign_2_3) {case 'Ari' : x_hh = 3 ; break ; 
                                                                case 'Tau' : x_hh = 5 ; break ; 
                                                                case 'Gem' : x_hh = 2 ; break ; 
                                                                case 'Can' : x_hh = 4 ; break ; 
                                                                case 'Leo' : x_hh = 2 ; break ; 
                                                                case 'Vir' : x_hh = 5 ; break ; 
                                                                case 'Lib' : x_hh = 2 ; break ; 
                                                                case 'Sco' : x_hh = 4 ; break ; 
                                                                case 'Sag' : x_hh = 1 ; break ; 
                                                                case 'Cap' : x_hh = 5 ; break ; 
                                                                case 'Aqu' : x_hh = 2 ; break ; 
                                                                case 'Pis' : x_hh = 4 ; break ; 
                                                                default    : x_hh = 0 ;         }  
                                                  
              } else if (sign_1_3 == 'Aqu') {switch(sign_2_3) { case 'Ari' : x_hh = 4 ; break ; 
                                                                case 'Tau' : x_hh = 2 ; break ; 
                                                                case 'Gem' : x_hh = 5 ; break ; 
                                                                case 'Can' : x_hh = 2 ; break ; 
                                                                case 'Leo' : x_hh = 2 ; break ; 
                                                                case 'Vir' : x_hh = 2 ; break ; 
                                                                case 'Lib' : x_hh = 5 ; break ; 
                                                                case 'Sco' : x_hh = 2 ; break ; 
                                                                case 'Sag' : x_hh = 4 ; break ; 
                                                                case 'Cap' : x_hh = 2 ; break ; 
                                                                case 'Aqu' : x_hh = 5 ; break ; 
                                                                case 'Pis' : x_hh = 3 ; break ; 
                                                                default    : x_hh = 0 ;         }  
                                                  
              } else if (sign_1_3 == 'Pis') { switch(sign_2_3) {                                                   
                                                                case 'Ari' : x_hh = 1 ; break ; 
                                                                case 'Tau' : x_hh = 4 ; break ; 
                                                                case 'Gem' : x_hh = 1 ; break ; 
                                                                case 'Can' : x_hh = 5 ; break ; 
                                                                case 'Leo' : x_hh = 3 ; break ; 
                                                                case 'Vir' : x_hh = 2 ; break ; 
                                                                case 'Lib' : x_hh = 3 ; break ; 
                                                                case 'Sco' : x_hh = 5 ; break ; 
                                                                case 'Sag' : x_hh = 2 ; break ; 
                                                                case 'Cap' : x_hh = 4 ; break ; 
                                                                case 'Aqu' : x_hh = 3 ; break ; 
                                                                case 'Pis' : x_hh = 5 ; break ; 
                                                                default    : x_hh = 0 ;         }  
                                                  }
                                                            let nbr_Hearts = x_hh ;  
                                                         return nbr_Hearts ;
  }
  }
  }