/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d14ddc04-a997-436d-8f3c-f2978c61323e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_CWglBlQOG",
    "aws_user_pools_web_client_id": "757ljrtifleka3sm3001nlf4ld",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://urwu2nsfmrdmfilvj3bapdwwry.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-kg5ibdrvpfcxvkcvr6pxjtqb7q"
};


export default awsmobile;
