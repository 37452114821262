<template>
  <v-app>

    <v-app-bar app color="#000033" dark max-height="60">

              <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

              <v-toolbar-title> astroSimpatico </v-toolbar-title>


              
                     <!-- router :to="item.bDate" -->
                       

    </v-app-bar>

    <v-main>
             <transition name = "slide-fade" mode = "out-in">
                                         <router-view />   
             </transition>
    </v-main>

    <!------------------------------------------------------------------------------------------------>
    <v-navigation-drawer 
       app 
       fixed
       v-model="drawer" >

      <v-list
                    dense
                    nav
                  >

                    <v-list-item
                                 v-for="item in items" :key="item.title" router :to="item.path" :color="item.color"
                    >
                          <v-list-item-icon >
                                              <v-icon  > {{ item.icon }} </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                                                <v-list-item-title> {{ item.title }} </v-list-item-title>
                          </v-list-item-content>

                    </v-list-item>

        </v-list>

    </v-navigation-drawer> 

    <!------------------------------------------------------------------------------------------------>
    <v-main>

    </v-main>  
    <!------------------------------------------------------------------------------------------------>

  </v-app>

</template>

<!------------------------------------------------------------------------------------------------>
<script>

//import Footer       from './components/Footer' ;

export default {
  name: 'App',

  data: () => ({
                drawer: false,
                                                // Material Design Icons 5.3.45 npm install @mdi/font - GitHub ...
                                                // https://pictogrammers.github.io/@mdi/font/5.3.45/
                                                // @mdi/font
                items: [
                        { title: 'what is',       icon: 'mdi-home',             path: '/'         },
                        { title: 'my people',     icon: 'mdi-account-multiple', path: '/bDate'    },
                        { title: 'famous',        icon: 'mdi-sunglasses',       path: '/famous'   },
                        { title: 'about',         icon: 'mdi-help-box',         path: '/about'    },
                        { title: 'chinese',       icon: 'mdi-yin-yang',         path: '/chinese'  },
                        { title: 'key',           icon: 'mdi-key',              path: '/key'      },
                        { title: 'support',       icon: 'mdi-send',             path: '/support'  },
                        { title: 'terms',         icon: 'mdi-gavel',            path: '/terms'    },
                        { title: 'login',         icon: 'mdi-login',            path: '/login'    },
                      ],
              }),

};

</script>


<!------------------------------------------------------------------>
<style>

.fade-enter {
              opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
                      transition: opacity 0.6s ease-out;
}

.fade-leave-to {
                  opacity: 0;
}

.slide-fade-enter {
                    transform: translateX(10px);  
                    opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
                            transition: all 0.6s ease;
}

.slide-fade-leave-to {
                        transform: translateX(-10px);  
                        opacity: 0;
}

.slide-up-enter {
                    transform: translateY(10px);  
                    opacity: 0;
}

.slide-fade-enter-active {
                            transition: all 0.6s ease;
}

</style>

