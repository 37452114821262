
export const getters = {

    methods: {

    //________________________________________________________________________________________    
    get_Sign_3(x_Date){

        let                bx = '' ;
                           bx = String(x_Date) ;
      
        let                mx = bx.substring(5,7);
      
        let dx = parseInt( bx.slice(-2) ) ;

        let                                         x_Sign_3 = '' ;
        switch ( mx ) {
                        case '01': if ( dx < 20 ) { x_Sign_3 = 'Cap' ;}
                                   else           { x_Sign_3 = 'Aqu' ;}  break ;

                        case '02': if ( dx < 20 ) { x_Sign_3 = 'Aqu' ;}
                                   else           { x_Sign_3 = 'Pis' ;}  break ;  

                        case '03': if ( dx < 21 ) { x_Sign_3 = 'Pis' ;}
                                   else           { x_Sign_3 = 'Ari' ;}  break ;  

                        case '04': if ( dx < 21 ) { x_Sign_3 = 'Ari' ;}
                                   else           { x_Sign_3 = 'Tau' ;}  break ;  

                        case '05': if ( dx < 21 ) { x_Sign_3 = 'Tau' ;}
                                   else           { x_Sign_3 = 'Gem' ;}  break ;  

                        case '06': if ( dx < 21 ) { x_Sign_3 = 'Gem' ;}
                                   else           { x_Sign_3 = 'Can' ;}  break ;  

                        case '07': if ( dx < 23 ) { x_Sign_3 = 'Can' ;}
                                   else           { x_Sign_3 = 'Leo' ;}  break ;  

                        case '08': if ( dx < 23 ) { x_Sign_3 = 'Leo' ;}
                                   else           { x_Sign_3 = 'Vir' ;}  break ;  

                        case '09': if ( dx < 23 ) { x_Sign_3 = 'Vir' ;}
                                   else           { x_Sign_3 = 'Lib' ;}  break ;  

                        case '10': if ( dx < 23 ) { x_Sign_3 = 'Lib' ;}  
                                   else           { x_Sign_3 = 'Sco' ;}  break ;  

                        case '11': if ( dx < 23 ) { x_Sign_3 = 'Sco' ;}
                                   else           { x_Sign_3 = 'Sag' ;}  break ;  

                        case '12': if ( dx < 22 ) { x_Sign_3 = 'Sag' ;}
                                   else           { x_Sign_3 = 'Cap' ;}  break ;  
 
                        }

        return x_Sign_3 ;
    },
    //_________________________________________________________________________________________
    get_Sign_Long(x_Sign_3){
                                                          let xx = ''
                            switch( x_Sign_3 ) {
                                                case 'Ari': { xx = '\u2648 Aries \u1f525'       ; break ; } 
                                                case 'Tau': { xx = '\u2649 Taurus \u1f30d'      ; break ; } 
                                                case 'Gem': { xx = '\u264a Gemini \u2708'      ; break ; } 
                                                case 'Can': { xx = '\u264b Cancer \u1F4A7'      ; break ; } 
                                                case 'Leo': { xx = '\u264c Leo  \u1f525'         ; break ; } 
                                                case 'Vir': { xx = '\u264d Virgo \u1f30d'       ; break ; } 
                                                case 'Lib': { xx = '\u264e Libra \u2708'       ; break ; } 
                                                case 'Sco': { xx = '\u264f Scorpio \u1F4A7'     ; break ; } 
                                                case 'Sag': { xx = '\u2650 Sagitarius \u1f525 '  ; break ; } 
                                                case 'Cap': { xx = '\u2651 Capricorn \u1f30d'   ; break ; } 
                                                case 'Aqu': { xx = '\u2652 Aquarius \u2708'    ; break ; } 
                                                case 'Pis': { xx = '\u2653 Pisces \u1F4A7'      ; break ; } 
                                                default:      xx = '...'      
                                            }              

                                         let    x_Sign_Long = xx
                                         return x_Sign_Long
                           },
    //_______________________________________________________________________________________
    get_Element(x_Sign_3) {
                                     let x_Element = '' ; 
        switch (x_Sign_3) {
                            case 'Ari' : x_Element = 'fire' ; break ;
                            case 'Leo' : x_Element = 'fire' ; break ;
                            case 'Sag' : x_Element = 'fire' ; break ;

                            case 'Cap' : x_Element = 'earth' ; break ;
                            case 'Tau' : x_Element = 'earth' ; break ;
                            case 'Vir' : x_Element = 'earth' ; break ;

                            case 'Lib' : x_Element = 'air' ; break ;
                            case 'Aqu' : x_Element = 'air' ; break ;
                            case 'Gem' : x_Element = 'air' ; break ;

                            case 'Can' : x_Element = 'water' ; break ;
                            case 'Sco' : x_Element = 'water' ; break ;
                            case 'Pis' : x_Element = 'water' ; break ;
                          }
                                  return x_Element ;                      
     },

 
 }                             
}

